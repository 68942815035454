import React, { useEffect } from "react";
import { Media } from "@ryerson/frontend.layout";
import { ContentSection } from "@ryerson/frontend.layout";
import styled from "@emotion/styled";
import { Typography } from "@ryerson/frontend.typography";
import { Icon } from "@ryerson/frontend.assets";
import { useTheme } from "@ryerson/frontend.theme";
import { css } from "@emotion/react";
import { useApplication } from "@ryerson/frontend.application";
import { IndustryLandingContent, STSIndustryLandingContent } from "./Content";
import { Link } from "@ryerson/frontend.navigation";

export type IndustryCard = {
	title: string;
	description: string;
	imageUrl: string;
	url: string;
};

export type IndustryContent = {
	cards: IndustryCard[];
	defaultImageUrl: string;
};

export type IndustryProps = {
	content: IndustryContent;
};

const MainContainer = styled.div`
	display: block;
	width: 100%;
	height: 650px;
`;

const Left = styled.div`
	display: block;
	position: absolute;
	right: 48.61%;
	width: 51.39%;
	max-width: 740px;
	height: auto;
`;

const IndustryDetail = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 370px;
	width: 50%;
	position: absolute;
	left: 0;
	justify-content: space-evenly;
	top: 0px;
	height: 100%;
	z-index: 1;
`;

const Right = styled.div`
	display: block;
	width: 48.61%;
	left: 51.39%;
	position: absolute;
	max-width: 700px;
	height: 630px;
`;

const IndustryList = styled.div`
	margin: 50px auto;
	position: relative;
	padding-left: 80px;
	box-sizing: border-box;
	display: block;
	position: relative;
	width: calc(70% - 80px);
	min-width: 444px;
`;

const IndustryListItem = styled.div`
	display: block;
	width: 100%;
	height: 61px;
	box-sizing: border-box;
	line-height: 60px !important;
	position: relative;
	cursor: pointer;
`;

const IndustryListItemIcon = styled.div`
	display: block;
	position: absolute;
	height: 24px;
	width: 24px;
	border-radius: 24px;
	right: 0;
	top: 18px;
	text-align: center;
	line-height: 24px !important;
`;

const HoverLine = styled.div`
	display: block;
	left: -80px;
	position: absolute;
	top: 29px;
	height: 1px;
	width: 65px;
`;

const MobileCard = styled.div`
	display: block;
	width: 100%;
	height: auto;
	padding-top: 20px;
	padding-bottom: 20px;
	max-width: 450px;
	margin: 0 auto;
	box-sizing: border-box;
`;

const MobileCardDescription = styled.div`
	width: 160px;
	display: inline-block;
	vertical-align: top;
	margin-right: 40px;
`;

const MobileCardImage = styled.div`
	width: calc(100% - 200px);
	height: 170px;
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: center center;
	position: relative;
	display: inline-block;
	vertical-align: top;
`;

const MobileIcon = styled.div`
	display: block;
	position: absolute;
	height: 24px;
	width: 24px;
	border-radius: 24px;
	left: -12px;
	top: 73px;
	text-align: center;
	line-height: 24px !important;
`;

const IndustriesWeServeLanding: React.FC = () => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();
	let content = IndustryLandingContent[language];
	if (process.env.GATSBY_COMPANY === "southernToolSteel") {
		content = STSIndustryLandingContent[language];
	}

	const [selectedIndex, setSelectedIndex] = React.useState<number>(-1);
	const [selectedImage, setSelectedImage] = React.useState<string>(content.defaultImageUrl);
	const [selectedTitle, setSelectedTitle] = React.useState<string>("");
	const [selectedDescription, setSelectedDescription] = React.useState<string>("");
	const HoverImageChange = (
		index: number,
		imageUrl: string,
		title: string,
		description: string
	) => {
		setSelectedIndex(index);
		setSelectedImage(imageUrl);
		setSelectedTitle(title);
		setSelectedDescription(description);
	};

	useEffect(() => {
		setSelectedIndex(-1);
		setSelectedImage(content.defaultImageUrl);
		setSelectedTitle("");
		setSelectedDescription("");
	}, [language]);

	return (
		<>
			<Media lessThan="lg">
				<ContentSection
					type="split"
					split={{
						splitColors: [
							{ color: theme.colors.primary.darkerGray, percentage: "100%" },
						],
					}}
				>
					{content.cards.map((card: IndustryCard, index: number) => {
						return (
							<MobileCard
								key={index}
								css={css`
									border-bottom: 1px solid
										${content.cards.length - 1 === index
											? "transparent"
											: "rgba(255,255,255,0.1)"};
								`}
							>
								<MobileCardDescription>
									<Typography
										variant="h4"
										css={css`
											margin-top: 5px;
											margin-bottom: 10px;
										`}
										type="tertiary"
									>
										{card.title}
									</Typography>
									<Typography variant="div" size="md" type="tertiary">
										{card.description}
									</Typography>
								</MobileCardDescription>
								<MobileCardImage
									css={css`
										background-image: url(${card.imageUrl});
									`}
								>
									<Link to={card.url} gatsby={true}>
										<MobileIcon
											css={css`
												background-color: ${theme.colors.primary
													.primaryBrand};
											`}
										>
											<Icon
												icon="chevron-right"
												size="xs"
												css={css`
													cursor: pointer;
												`}
												color={theme.colors.primary.white}
											/>
										</MobileIcon>
									</Link>
								</MobileCardImage>
							</MobileCard>
						);
					})}
				</ContentSection>
			</Media>
			<Media greaterThanOrEqual="lg">
				<ContentSection type="secondary" vPadding="0">
					<MainContainer>
						<Left>
							<img
								src={selectedImage}
								css={css`
									width: 100%;
									height: auto;
								`}
							/>
							{selectedTitle &&
								selectedTitle.length > 0 &&
								selectedDescription &&
								selectedDescription.length > 0 && (
									<IndustryDetail>
										<Typography
											variant="h1"
											css={css`
												margin: 0;
												padding-left: 30%;
												box-sizing: border-box;
												max-width: 100%;
											`}
											type="tertiary"
										>
											{selectedTitle}
										</Typography>
										<Typography
											variant="div"
											css={css`
												color: ${theme.colors.primary.white};
												padding: 15px;
												box-sizing: border-box;
												max-width: 100%;
												background-color: rgba(0, 0, 0, 0.5);
												padding-left: 30%;
												box-sizing: border-box;
											`}
											size="md"
										>
											{selectedDescription}
										</Typography>
									</IndustryDetail>
								)}
						</Left>
						<Right
							css={css`
								background-color: ${theme.colors.primary.darkerGray};
							`}
						>
							<IndustryList>
								{content.cards.map((card: IndustryCard, index: number) => {
									return (
										<Link to={card.url} gatsby={true} key={index}>
											<IndustryListItem
												css={css`
													border-bottom: 1px solid
														${index === content.cards.length - 1
															? "transparent"
															: "rgba(255,255,255,0.1)"};
												`}
												onMouseOver={() => {
													HoverImageChange(
														index,
														card.imageUrl,
														card.title,
														card.description
													);
												}}
											>
												<HoverLine
													css={css`
														background-color: ${selectedIndex === index
															? theme.colors.primary.primaryBrand
															: "transparent"};
													`}
												/>

												<Typography
													type="tertiary"
													size="md"
													variant="span"
												>
													{card.title}
												</Typography>
												<IndustryListItemIcon
													css={css`
														border: 1px solid
															${selectedIndex === index
																? theme.colors.primary.primaryBrand
																: theme.colors.primary.darkGray};

														background-color: ${selectedIndex === index
															? theme.colors.primary.primaryBrand
															: "transparent"};
													`}
												>
													<Icon
														icon="chevron-right"
														size="xs"
														css={css`
															cursor: pointer;
														`}
														color={theme.colors.primary.white}
													/>
												</IndustryListItemIcon>
											</IndustryListItem>
										</Link>
									);
								})}
							</IndustryList>
						</Right>
					</MainContainer>
				</ContentSection>
			</Media>
		</>
	);
};

export default IndustriesWeServeLanding;
